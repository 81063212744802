// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-photos-tsx": () => import("./../../../src/pages/photos.tsx" /* webpackChunkName: "component---src-pages-photos-tsx" */),
  "component---src-pages-projects-hackmanchester-bio-app-tsx": () => import("./../../../src/pages/projects/hackmanchester-bio-app.tsx" /* webpackChunkName: "component---src-pages-projects-hackmanchester-bio-app-tsx" */),
  "component---src-pages-projects-index-tsx": () => import("./../../../src/pages/projects/index.tsx" /* webpackChunkName: "component---src-pages-projects-index-tsx" */),
  "component---src-pages-projects-leedshack-puzzle-game-tsx": () => import("./../../../src/pages/projects/leedshack-puzzle-game.tsx" /* webpackChunkName: "component---src-pages-projects-leedshack-puzzle-game-tsx" */),
  "component---src-pages-projects-outbreak-sim-tsx": () => import("./../../../src/pages/projects/outbreak-sim.tsx" /* webpackChunkName: "component---src-pages-projects-outbreak-sim-tsx" */),
  "component---src-pages-projects-oxfordhack-rinder-for-teddit-tsx": () => import("./../../../src/pages/projects/oxfordhack-rinder-for-teddit.tsx" /* webpackChunkName: "component---src-pages-projects-oxfordhack-rinder-for-teddit-tsx" */),
  "component---src-pages-projects-p-5-graph-tsx": () => import("./../../../src/pages/projects/p5-graph.tsx" /* webpackChunkName: "component---src-pages-projects-p-5-graph-tsx" */),
  "component---src-pages-projects-p-5-snake-tsx": () => import("./../../../src/pages/projects/p5-snake.tsx" /* webpackChunkName: "component---src-pages-projects-p-5-snake-tsx" */),
  "component---src-pages-projects-portfolio-website-tsx": () => import("./../../../src/pages/projects/portfolio-website.tsx" /* webpackChunkName: "component---src-pages-projects-portfolio-website-tsx" */),
  "component---src-pages-projects-studenthack-flipping-flying-triangle-tsx": () => import("./../../../src/pages/projects/studenthack-flipping-flying-triangle.tsx" /* webpackChunkName: "component---src-pages-projects-studenthack-flipping-flying-triangle-tsx" */),
  "component---src-pages-projects-web-gl-particles-tsx": () => import("./../../../src/pages/projects/web-gl-particles.tsx" /* webpackChunkName: "component---src-pages-projects-web-gl-particles-tsx" */)
}

